import styles from 'bundle-text:./index.css';

  window.ZwappyStory = {

    options: {
      title: "",
      image: "",
      url: "",
      themeColor: ""
    },

    content: [
      {
        title: 'Heb jij een probleem met het krijgen van goede zorg of jeugdhulp?',
        path: '/',
        image: 'https://jeugdombudsman.zwappy.nl/klachtkaart/embed/img/poster.jpg',
        url: 'https://jeugdombudsman.zwappy.nl/klachtkaart',
        domain: 'denhaagfietst.nl'
      }
    ],

    addStyles: function () {
      const style = document.createElement('style');
      style.innerHTML = styles
      document.head.appendChild(style);
    },

    setOptions: function (options) {
      if (options.title) {
        this.options.title = options.title;
      }
      if (options.image) {
        this.options.image = options.image;
      }
      if (options.url) {
        this.options.url = options.url;
      }
      // if (options.themeColor) {
      //   this.options.themeColor = options.themeColor;
      // } else {
      //   this.options.themeColor = 'black'
      // }
      this.options.themeColor = '#46a11f';
    },

    render: function (options) {
      const paths = options && options.paths ? options.paths : ['/']
      console.log(paths)
      const content = this.getContent(paths)
      if (!content) {
        return
      }
      this.setOptions(content)
      this.addStyles()
      const badge = document.createElement("div")
      badge.id = 'zwappy-msg'
      badge.innerHTML = `
        <div id="zwappy-message">
          <p>
            ${this.options.title}
            <a id="zwappy-close-popup">Sluiten</a>
          </p>
          <button class="zwappy-read zwappy-open">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" style="enable-background:new 0 0 42 42" xml:space="preserve"><circle fill="${this.options.themeColor}" cx="21" cy="21" r="21"/><path d="M29.2 20.3 24.9 16c-.4-.4-1-.4-1.3 0-.4.4-.4 1 0 1.3l2.7 2.7H11.4c-.5 0-.9.4-.9.9s.4.9.9.9h14.9l-2.7 2.7c-.4.4-.4 1 0 1.3.2.2.4.3.7.3s.5-.1.7-.3l4.3-4.3c.3-.2.3-.8-.1-1.2z" style="fill:#fff"/></svg>
          </button>
        </div>
        <div id="zwappy-inner-circle"></div>
          <div id="zwappy-inner-content" class="zwappy-open">
            <div id="zwappy-image" style="background-image: url('https://jeugdombudsman.zwappy.nl/klachtkaart/embed/img/poster.jpg');">
              <video
                poster="https://jeugdombudsman.zwappy.nl/klachtkaart/embed/img/poster.jpg"
                class="w-full h-full object-cover absolute left-0 top-0"
                muted
                playsinline
                loop
                autoplay
              >
                <source src="https://jeugdombudsman.zwappy.nl/klachtkaart/embed/video/promo.mp4" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
      `
      badge.dataset.webstory = this.options.url
      document.body.appendChild(badge)

      Array.from(document.querySelectorAll('.zwappy-open')).forEach(item => {
        item.addEventListener("click", (event) => {
          event.preventDefault()
          this.showOverlay(badge.getAttribute('data-webstory'))
        });
      })

      document.getElementById('zwappy-close-popup').addEventListener("click", () => {
        document.querySelector("#zwappy-msg").remove()
      })

      this.initClickElements()
    },

    getContent: function (paths) {
      const path = document.location.pathname
      let data = undefined
      if (paths.includes(path)) {
        data = this.content[0]
      }
      return data !== undefined ? data : false
    },

    initClickElements: function () {
      document.querySelectorAll('.zwappy-click').forEach((button) => {
        button.addEventListener("click", (event) => {
          event.preventDefault()
          this.showOverlay(button.getAttribute('data-webstory'))
        });
      });
    },

    showOverlay: function (webstory) {
      const el = document.createElement("div")
      const elStory = document.createElement("div")
      const closeButton = document.createElement("a")
      const iframe = document.createElement("iframe");

      el.id = "zwappy-overlay"
      elStory.id = "zwappy-story"
      closeButton.id = "zwappy-close"

      closeButton.addEventListener("click", this.closeOverlay)

      iframe.setAttribute("src", webstory)
      iframe.style.width = "100%"
      iframe.style.height = "100%"
      iframe.style.border = 'none'

      el.appendChild(elStory)
      elStory.appendChild(iframe)
      elStory.appendChild(closeButton)
      document.body.appendChild(el)
    },

    closeOverlay: function () {
      const overlay = document.querySelector("#zwappy-overlay")
      overlay.remove()
    }

  }